<template>
  <div class="page-container">
    <home-swiper :banner="banner"/>
    <recommemd-list :advert="advert"></recommemd-list>
    <notice-bar :notice="notice"></notice-bar>
    <brand-list :brand="brand"></brand-list>
    <processes></processes>
    <contact-us :contact="contact" :project_name="contact.project_name"></contact-us>
    <main-tab-bar/>
  </div>
</template>

<script>
  import MainTabBar from 'components/content/mainTabbar/MainTabBar'
  import HomeSwiper from './childComps/HomeSwiper'
  import NoticeBar from './childComps/NoticeBar'
  import RecommemdList from './childComps/RecommendList'
  import BrandList from './childComps/BrandList'
  import Processes from './childComps/Processes'
  import ContactUs from './childComps/ContactUs'

  import { getIndexPageConfig } from 'api/api'

  export default {
    name: 'home',
    data () {
      return {
        banner: [],
        notice: [],
        advert: [],
        brand: [],
        contact: {},
      }
    },
    components: {
      MainTabBar,
      BrandList,
      HomeSwiper,
      RecommemdList,
      NoticeBar,
      Processes,
      ContactUs
    },
    created () {
      this.getIndexElement()
    },
    methods: {
      getIndexElement () {
        getIndexPageConfig().then(res => {
          this.banner = res.banner
          this.notice = res.notice
          this.contact = res.contact
          this.advert = res.advert
          this.brand = res.brand
          document.title = res.contact.project_name
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .page-container {
    padding-bottom: 49px;
  }
</style>
