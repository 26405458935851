<template>
  <div class="recommend-list" v-if="advert && advert.length>0">
    <div class="recommend-list-item" v-for="item in advert" :key="item.id">
      <img :src="item.image" alt="">
    </div>
  </div>
</template>

<script>

  export default {
    name: 'RecommendList',
    props:{
      advert:Array
    }
  }
</script>

<style scoped>
  .recommend-list{
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.3rem 0;
  }
  .recommend-list-item{
    flex: 0 0 2.1rem;
  }
  .recommend-list-item img{
    width: 100%;
  }

</style>
