<template>
  <div class="processes-wrapper">
    <div class="title">
      <div class="tag">发货下单</div>
      <div class="tag">到货验机</div>
      <div class="tag">确认报价</div>
      <div>成交收款</div>
    </div>
    <div class="content">
      <div class="img-box">
        <img src="~assets/images/process/process1.png" alt="">
      </div>
      <div class="img-box">
        <img src="~assets/images/process/process2.png" alt="">
      </div>
      <div class="img-box">
        <img src="~assets/images/process/process3.png" alt="">
      </div>
      <div class="img-box">
        <img src="~assets/images/process/process4.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Processes'
  }
</script>

<style lang="less" scoped>
  .processes-wrapper{
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.6rem;
    font-size: 0.28rem;
  .title{
    height: 1.08rem;
    padding: 0 0.76rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-family: 'Source Han Sans CN';
    font-weight: bold;
    color: #666;
  .tag{
    position: relative;
  &:after{
     content: '';
     position: absolute;
     top: 50%;
     right: -0.46rem;
     transform: translateY(-50%);
     width: 0;
     height: 0;
     border: 0.12rem solid;
     border-color: transparent transparent transparent #666;
   }
  }
  }
  .content{
    display: flex;
    padding: 0 0.3rem;
    justify-content: space-between;
    flex-wrap: wrap;
  .img-box{
    width: 3.3rem;
    height: 1.36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.05rem;
    background-color: #fff;
    margin-bottom: 0.2rem;
  img{
    width: 2.04rem;
    height: 0.7rem;
  }
  }
  }
  }
</style>
