<template>
  <van-notice-bar left-icon="volume-o" :scrollable="false" color="#ff5100" background="#f8f8f8" v-if="notice && notice.length> 0">
    <van-swipe
      vertical
      class="notice-swipe"
      :autoplay="2000"
      :show-indicators="false"
    >
      <van-swipe-item v-for="item in notice" :key="item.id">{{item.content}}</van-swipe-item>
    </van-swipe>
  </van-notice-bar>
</template>

<script>
  export default {
    name: 'NoticeBar',
    props:{
      notice:{
        type: Array,
        default(){
          return []
        }
      }
    }
  }
</script>

<style scoped>
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
</style>
