<template>
  <div class="contact-wrapper">
    <div class="title">
      <div class="text1">{{project_name}}平台欢迎您！</div>
      <div class="text2">竭诚为您服务</div>
    </div>
    <div class="content">
      <div class="item">
        <a :href="'tel:'+contact.mobile" class="item-a">
          <div>
            <img class="img" src="~assets/images/other/dianhua@2x.png" alt="">
          </div>
          <div>电话咨询</div>
        </a>
      </div>
      <div class="item" @click="copyWechat" :wxcode="contact.wx">
        <div>
          <img class="img" src="~assets/images/other/weixin@2x.png" alt="">
        </div>
        <div>复制微信</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { copyFn } from 'utils/util'

  export default {
    name: 'ContactUs',
    props: {
      contact: Object,
      project_name: String
    },
    methods: {
      copyWechat () {
        copyFn('微信号', this.contact.wx)
      }
    },
    watch: {
      contact: {
        handler (newVal, oldVal) {
          this.contact = newVal
        },
        immediate: true,
        deep: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .contact-wrapper {
    box-sizing: border-box;
    font-size: 0.28rem;
    margin-bottom: 0.4rem;

    .title {
      text-align: center;
      font-family: 'PingFang SC';
      font-size: 0.26rem;
      color: #666666;
      margin-bottom: 0.3rem;

      .text1 {
        font-weight: bold;
        margin-bottom: 0.1rem;
      }

      .text2 {
        font-weight: 500;
      }
    }

    .content {
      display: flex;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        color: #666;

        .item-a {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 0 0 100%;
          width: 100%;
          color: #666;
          border-right: 1px solid #ccc;
        }

        .img {
          width: 0.48rem;
          height: 0.48rem;
        }
      }
    }
  }
</style>
