<template>
  <div class="brand-price-wrapper">
    <div class="brand-list-wrapper">
      <div class="title-wrapper">
        <div class="title">二手回收报价</div>
      </div>
      <div class="brand-list">
        <div class="brand-item" v-for="item in brand" @click="goToCategory(item.id)">
          <img class="brand-logo" :src="item.logo" alt="" :key="item.id">
          <div>{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BrandList',
    props:{
      brand:Array
    },
    methods:{
      goToCategory(index){
        this.$router.push({
          path:'/category',
          query:{
            index:index
          }
        })
        this.resetKeepAive('category')
      }
    }
  }
</script>

<style lang="less" scoped>
  .brand-price-wrapper {
    padding:0 0.3rem;
    margin-bottom: 0.3rem;

    .brand-list-wrapper {
      width: 100%;
      border-radius: 0.1rem;
      box-shadow: 0 0.03rem 0.5rem 0 rgba(0, 0, 0, 0.1);
      padding-bottom: 0.3rem;
      background-color: #fff;

      .title-wrapper {
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.2rem 0.3rem 0.4rem;
        margin: 0;
        position: relative;

        .title {
          padding-left: 0.3rem;
          font-weight: bold;
          font-family: 'PingFang SC';
          display: inline-block;
          font-size: 0.32rem;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 0.12rem;
            height: 0.38rem;
            background-color: #FF5100;
          }
        }
      }

      .brand-list {
        display: flex;
        flex-flow: wrap;
        padding: 0 0.2rem;

        .brand-item {
          width: 25%;
          text-align: center;
          font-size: 0.28rem;
          margin-bottom: 0.4rem;

          .brand-logo {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 20 rpx;
          }

        }
      }

    }
  }
</style>
