<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="banner && banner.length> 0">
    <van-swipe-item v-for="item in banner" :key="item.id">
      <img :src="item.image" alt="">
    </van-swipe-item>
  </van-swipe>
</template>

<script>

  export default {
    name: 'HomeSwiper',
    props: {
      banner: {
        type: Array,
        default () {
          return []
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  .my-swipe .van-swipe-item {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }
</style>
